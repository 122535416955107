import { AxiosInstance } from "axios";
import { App, Plugin } from "vue";
import publicFormApiAxios from "./publicFormApiAxios";
import callGeneral from "./calls/callGeneral";
import callCompanyForm from "./calls/callCompanyForm";
import callForm from "./calls/callForm";
import callPersonForm from "./calls/callPersonForm";
import callValidation from "./calls/callValidation";
import callCompanyUpdateForm from "./calls/callCompanyUpdateForm";

class PublicFormApi {
	axios: AxiosInstance;

	constructor() {
		this.axios = publicFormApiAxios;
	}

	// Only generate this object once
	private callObjects = {
		...callGeneral,
		...callCompanyForm,
		...callForm,
		...callPersonForm,
		...callValidation,
		...callCompanyUpdateForm,
		...callCompanyForm,
	};

	private functionObjects = {};

	/**
	 * Returns the 'call' object. Usage: `call.controller.method.action()`
	 */
	get call() {
		return this.callObjects;
	}

	get functions() {
		return this.functionObjects;
	}
}

export const publicFormApi = new PublicFormApi();

export const apiVuePlugin = {
	install(app: App) {
		app.config.globalProperties.$apiBaseUrl = publicFormApiAxios.defaults.baseURL || "";
		app.config.globalProperties.$api = publicFormApi;
	},
} as Plugin;

declare module "@vue/runtime-core" {
	//Bind to `this` keyword
	interface ComponentCustomProperties {
		$apiBaseUrl: string;
		$api: PublicFormApi;
	}
}

export default publicFormApi;
