import ResponseError from "@/types/interfaces/ResponseError";
import type { AxiosError, AxiosRequestConfig } from "axios";
import publicFormApiAxios from "./publicFormApiAxios";
/**
 * ApiResponse object. Contains either the data or error object.
 * Check the `isOk` & `isHardFailure` to check if response is an error.
 * `isHardFailure` means that server returned 500 (server error).
 * In that case, the error object will be null.
 */
export class ApiResponse<T> {
	/**
	 * Contains Response Data, if there are no errors
	 */
	readonly data: T | null;
	/**
	 * True, if there is any kind of failure.
	 */
	readonly isFailure: boolean;
	/**
	 * True, if the server crashed (500 internal server error).
	 */
	readonly isHardFailure: boolean;
	/**
	 * Array of errors
	 */
	readonly errors: ResponseError[] | null;

	/**
	 * Gets the first error of errors array.
	 */
	get error() {
		if (this.errors) {
			return this.errors[0];
		}
		return null;
	}

	/**
	 * Same as isFailure but reversed.
	 */
	get isSuccessful() {
		return !this.isFailure;
	}

	/**
	 * Returns true if `isFailure` is true and `isHardFailure` is false.
	 */
	get isHandledFailure() {
		return this.isFailure && !this.isHardFailure;
	}

	constructor(
		data: T | null,
		isFailure: boolean,
		isHardFailure: boolean,
		errors: ResponseError[] | null
	) {
		this.data = data;
		this.isFailure = isFailure;
		this.isHardFailure = isHardFailure;
		this.errors = errors;
	}
}

/**
 * Class that generates ApiResponse objects.
 */
export default class ApiRespGen<T> {
	/**
	 * Request was succesful
	 * @param data - The data object that server is returning
	 */
	success(data: T): ApiResponse<T> {
		return new ApiResponse<T>(data, false, false, null);
	}

	/**
	 * Request wasn't successful, server returned an handled error.
	 * @param error - ResponseError object. API should return this on 4XX responses.
	 */
	failure(errors: ResponseError[]): ApiResponse<T> {
		return new ApiResponse<T>(null, true, false, errors);
	}

	/**
	 * Hard fail, usually meaning server returns 500
	 */
	failureHard(): ApiResponse<T> {
		return new ApiResponse<T>(null, true, true, null);
	}

	/**
	 * Automatically handles the error object from try-catch
	 * @param ex - Error object, possibly AxiosError
	 */
	autoDetectFailure(ex: any): ApiResponse<T> {
		if (ex.response && ex.response.status) {
			const axiosErr = ex as AxiosError;

			const responseData = axiosErr.response!.data as ResponseError;
			let errArray = [] as ResponseError[];
			if (responseData) {
				if (
					Array.isArray(responseData) &&
					responseData.length > 0 &&
					responseData[0].codeName
				) {
					errArray = responseData;
				} else if (responseData.codeName) {
					errArray.push(responseData);
				}
			}

			if (axiosErr.response!.status < 500 && errArray.length > 0) {
				return this.failure(errArray);
			}
		}

		return this.failureHard();
	}
}

export async function simpleCall<T>(
	method: "get" | "post" | "patch" | "delete",
	url: string,
	config: AxiosRequestConfig | null = null
) {
	try {
		const axiosConfig = config || {};
		axiosConfig.method = method;

		const response = await publicFormApiAxios(url, axiosConfig);
		const data = response.data as T;
		return new ApiRespGen<T>().success(data);
	} catch (ex: unknown) {
		return new ApiRespGen<T>().autoDetectFailure(ex);
	}
}
